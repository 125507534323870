import { Bar, BarChart, XAxis } from "recharts";
import { data } from "../constants/chartData";

const CustomizedTick = (props) => {
  const { x, y, stroke, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666">
        <tspan textAnchor="middle" x="0" fontSize="12px" fill="#fff">
          {payload.value}
        </tspan>
        <tspan textAnchor="middle" x="0" dy="20" fill="#fff" fontSize="12px">
          {payload.index === 0
            ? "(< 18.5)"
            : payload.index === 1
            ? "(18.5-24.9)"
            : payload.index === 2
            ? "(25-29.9)"
            : "(>30)"}
        </tspan>
      </text>
    </g>
  );
};

const Chart = () => (
  <BarChart
    width={300}
    height={120}
    data={data}
    margin={{
      top: 0,
      right: 0,
      left: 0,
      bottom: 15,
    }}
  >
    <XAxis
      dataKey="name"
      // textAnchor="end"
      sclaeToFit="true"
      verticalAnchor="start"
      interval={0}
      // angle="-30"
      // stroke="#fff"
      // fontSize="12px"
      tick={<CustomizedTick />}
    />
    <Bar dataKey="uv" fill="#FF0000" />
  </BarChart>
);

export default Chart;
