import { useEffect, useRef, useState } from "react";
import { Box, Button, Icon, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { MdIosShare } from "react-icons/md";

import PageTop from "../components/PageTop";
import PageMid from "../components/PageMid";
import bgInvert from "../assets/bi_invert.png";
import { AddToHomeScreen } from "react-pwa-add-to-homescreen";
import moment from "moment";
import axios from "axios";

const BloodPressureInfo1 = () => {
  const navigate = useNavigate();
  const bottomRef = useRef();

  const handleCheckBP = () => {
    navigate("/bp2");
  };

  const toast = useToast();

  const handleContinue = async () => {
    let canScan;
    try {
      const loginObj = JSON.parse(localStorage.getItem("login"));
      var promoCode = localStorage.getItem("promoCode");
      if(!promoCode){
        promoCode = "";
      }else{
        promoCode = promoCode.replace(/ /g,'').toLowerCase();
      }

      const url = `https://healthscanner.app/service/canPerformScan?userName=${
        loginObj.countryCode
      }${loginObj.username}&reqScanDateTime=${new Date().toISOString()}`;

      const res = await axios.get(url);
      // console.log("Can scan:: ", res);
      canScan = res.data;

      if (canScan || promoCode === "bobperry") {
        return handleCheckBP();
      } else {
        const lastScannedDate = localStorage.getItem("lastScan");
        let daysRemaining;

        if(lastScannedDate){
          let d1 = moment(); //current day
          let d2 = moment(lastScannedDate, "DD-MM-YYYY"); //scanned day
          daysRemaining = 5 - d1.diff(d2, 'days');
          // if (moment().format("M") === moment(lastScannedDate).format("M")) {
          //   daysRemaining =
          //     5 -
          //     (Number(moment().format("D")) -
          //       Number(moment(lastScannedDate).format("D")));
          // } else if (
          //   Number(moment().format("M")) >
          //   Number(moment(lastScannedDate).format("M"))
          // ) {
          //   daysRemaining =
          //     5 -
          //     (Number(moment().format("D")) +
          //       30 -
          //       Number(moment(lastScannedDate).format("D")));
          // }
        }

        return toast({
          title: "You cannot perform the scan now",
          description: `${
            daysRemaining
              ? `You can perform the scan after ${daysRemaining} days`
              : "Please try again later"
          }`,
          status: "error",
          duration: 5000,
        });
      }
    } catch (e) {
      console.log("Backend err::", e);
      alert(
        "There seems to be a problem, do you want to move on next screen with older results ?"
      );
      navigate("/bp-result");
    }
  };

  // const clearLocal = () => {
  //   localStorage.clear();
  //   alert("test")
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    //Checking send message here
    /*axios.get('/offlinetoken').then((res) => {
        alert(JSON.stringify(res))
    });*/
    /* fetch("/offlinetoken")
      .then(res => res.json())
      .then(
        (result) => {
          let value = JSON.parse(result);
          if(value){
            alert(result);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          alert("Error in getting token");
        }
      ) */
  }, []);

  return (
    <>
      <AddToHomeScreen />
      <PageTop heading="The CDL Health Scanner"/>
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <Text
          color="white"
          fontSize={{ vxs: 13, xxs: 15, xs: 17, sm: 20 }}
          mb={{
            vxs: "3 !important",
            xxxs: "3 !important",
            xxs: "2 !important",
            sm: "5 !important",
          }}
          textAlign="justify"
        >
          This simple scan alerts drivers to their personal risk for
          hypertension, type2 diabetes and sleep apnea. These three conditions
          account for almost 80% of DOT medical disqualifications. A two-minute
          scan can protect your license and your livelihood.
        </Text>
        <Text
          color="white"
          fontSize={{ vxs: 13, xxs: 15, xs: 17, sm: 18 }}
          mb={{ vxs: "3 !important", xxxs: "3 !important", sm: "5 !important" }}
          textAlign="justify"
        >
          Using the revolutionary Anura technology, your smartphone camera
          delivers a blood pressure (BP) measurement that is for
          investigational, not diagnostic, purposes. Then, subsequent screens
          utilize a body mass index (BMI) calculation and neck measurement to
          alert drivers to other issues that can impact their medical
          examination.
        </Text>
        <Text
          color="white"
          fontSize={{ vxs: 13, xxs: 15, xs: 17, sm: 18 }}
          textAlign="justify"
        >
          The app also provides information on DOT health requirements and
          connections to companies that can assist drivers who need to improve
          their health. The maker of the app has vetted these companies but has
          no financial interest in them.
        </Text>
        {navigator.platform !== "iPhone" ? (
          <Box
            bgImage={bgInvert}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgBlendMode="lighten"
            px={{ vxs: "1", xs: "3" }}
            pb={{ vxs: "1", xs: "3" }}
            rounded="md"
            mt="10 !important"
          >
            <Text
              textAlign="justify"
              mt={{ vxs: "1 !important", xs: "4 !important" }}
              color="black"
            >
              For regular use this app MUST be bookmarked by tapping the star
              icon on the top right corner of the screen.
            </Text>
          </Box>
        ) : (
          <Box
            bgImage={bgInvert}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgBlendMode="lighten"
            px={{ vxs: "1", xs: "3" }}
            pb={{ vxs: "1", xs: "3" }}
            rounded="md"
            mt="10 !important"
          >
            <Text
              color="black"
              fontSize={{ vxs: 12, xxxs: 14, xs: 16 }}
              textAlign="center"
            >
              Please click on <Icon as={MdIosShare} w={4} h={4} mx="1" /> and
              scroll to 'Add to Home Screen' to bookmark this app
            </Text>
          </Box>
        )}
        <Button
          ref={bottomRef}
          mt={{
            vxs: "4 !important",
            xxxs: "auto !important",
            sm: "3 !important",
          }}
          mb="3 !important"
          w="100%"
          bg="red"
          color="white"
          py={{ vxs: "2", xxxs: "0", sm: "2" }}
          fontSize={{ vxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={handleContinue}
        >
          NEXT - Check your BP
        </Button>
      </PageMid>
    </>
  );
};

export default BloodPressureInfo1;
