import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: {
    vxs: "300px",
    xxxs: "350px",
    xxs: "370px",
    xs: "390px",
    sm: "400px",
  },
});

export default theme;
