import React, { useEffect, useRef, useState } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Icon,
  Image,
  VStack,
  Box,
  Button,
  useToast,
} from "@chakra-ui/react";

import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";
import { Buffer } from "buffer";
import { publicEncrypt } from "crypto-browserify";
import moment from "moment";

import PageMid from "../components/PageMid";
import PageTop from "../components/PageTop";
import scanBg from "../assets/bp.png";
import { publicKey } from "../constants/publicKey";

const BloodPressureInfo2 = () => {
  const navigate = useNavigate();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();

  const bottomRef = useRef();

  const toast = useToast();

  const navigateToNeuralogix = async () => {
    if (!age || !gender) return alert("Please enter age and gender");
    localStorage.setItem("ageGender", JSON.stringify({ age, gender }));

    try {
      const { data: configResponseData } = await axios.get("/api/configId");
      // // Get a token from the back end
      const { data: tokenResponseData } = await axios.post("/api/token");

      const profileData = {
        identifier: uuidv4(),
        age: age ? age : 44,
        // height: 175,
        // weight: 89,
        gender: gender ? (gender === "M" ? "male" : "female") : "male",
        // smoking: "0",
        // bloodpressuremedication: "0",
        // diabetes: "type2",
      };

      const buffer = Buffer.from(JSON.stringify(profileData));
      const encryptedProfile = publicEncrypt(publicKey, buffer);
      const sessionId = uuidv4();

      //Capture the URL first here
      const anuraURL = `${
        configResponseData.configId
      }/${encodeURIComponent(
        encryptedProfile.toString("base64")
      )}/${encodeURIComponent(tokenResponseData.Token)}/${encodeURIComponent(tokenResponseData.RefreshToken)}/${sessionId}`;

      const captureanuralog = await axios.post("/anuralog/"+anuraURL);

      window.location.href = `https://awe.na-east.nuralogix.ai/c/`+anuraURL;
    } catch (err) {
      console.log(err);
      throw new Error(err.message);
    }
  };

  // const handleContinue = async () => {
  //   if (!age || !gender) return alert("Please enter age and gender");
  //   localStorage.setItem("ageGender", JSON.stringify({ age, gender }));

  //   let canScan;
  //   try {
  //     const loginObj = JSON.parse(localStorage.getItem("login"));
  //     const promoCode = localStorage.getItem("promoCode");

  //     const url = `https://healthscanner.app/service/canPerformScan?userName=${
  //       loginObj.countryCode
  //     }${loginObj.username}&reqScanDateTime=${new Date().toISOString()}`;

  //     const res = await axios.get(url);
  //     console.log("Can scan:: ", res);
  //     canScan = res.data;

  //     if (canScan || promoCode === "bobperry") {
  //       await navigateToNeuralogix();
  //     } else {
  //       const lastScannedDate = localStorage.getItem("lastScan");
  //       let daysRemaining;

  //       if (moment().format("M") === moment(lastScannedDate).format("M")) {
  //         daysRemaining =
  //           5 -
  //           (Number(moment().format("D")) -
  //             Number(moment(lastScannedDate).format("D")));
  //       } else if (
  //         Number(moment().format("M")) >
  //         Number(moment(lastScannedDate).format("M"))
  //       ) {
  //         daysRemaining =
  //           5 -
  //           (Number(moment().format("D")) +
  //             30 -
  //             Number(moment(lastScannedDate).format("D")));
  //       }

  //       return toast({
  //         title: "You cannot perform the scan now",
  //         description: `${
  //           JSON.parse(daysRemaining)
  //             ? `You can perform the scan after ${daysRemaining} days`
  //             : "You cannot perform the scan now"
  //         }`,
  //         status: "error",
  //         duration: 5000,
  //       });
  //     }
  //   } catch (e) {
  //     console.log("Backend err::", e);
  //     alert(
  //       "looks like server is not running ,do you want to move on next screen with static result ?"
  //     );
  //     navigate("/bp-result");
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTop heading="Blood Pressure" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <Flex style={{ margin: "0px !important" }}>
          <Flex
            alignItems="flex-start"
            flexDirection="column"
            padding="1"
            flexBasis="60%"
          >
            <FormControl
              display="flex"
              w={{ vxs: "50%", xxxs: "40%", sm: "30%" }}
              alignItems="center"
              mb="1"
              p="1"
            >
              <FormLabel color="white" mb={-0.8} fontSize={{ xxs: 18 }}>
                Age
              </FormLabel>
              <Input
                type="number"
                placeholder="Age"
                fontSize={{ xxs: 18 }}
                border="1px solid white"
                p="2"
                minWidth="80px"
                color="white"
                onChange={(e) => setAge(e.target.value)}
                _focusVisible={{ outline: "none" }}
              />
            </FormControl>
            <FormControl display="flex" p="2">
              <RadioGroup value={gender} onChange={setGender}>
                <FormLabel color="white" fontSize={{ xxs: 18 }}>
                  Gender
                </FormLabel>
                <Stack color="white" direction="row">
                  <Radio
                    mr="2"
                    value="M"
                    bg="white"
                    size={{ vxs: "sm", xxxs: "md", sm: "lg" }}
                  >
                    Male
                  </Radio>
                  <Radio
                    value="F"
                    bg="white"
                    size={{ vxs: "sm", xxxs: "md", sm: "lg" }}
                  >
                    Female
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
          <Flex>
            <Image
              src={scanBg}
              w={{ vxs: "150px" }}
              h={{ vxs: "150px" }}
              maxWidth="200px"
            />
          </Flex>
        </Flex>
        <Text
          color="red"
          fontWeight="bold"
          fontSize={{ xxxs: 20, xs: 24 }}
          textAlign="left"
          w="100%"
          letterSpacing={0.5}
          mt={{ vxs: "-4 !important", xxxs: 0 }}
        >
          Instructions
        </Text>
        <VStack
          color="white"
          fontSize={{ xs: 16, sm: 18 }}
          spacing={{ xxxs: 4, sm: 6 }}
        >
          <Text fontSize={{ vxs: 13, xxxs: 15, xs: 17 }}>
            Please ensure that the following guidelines are met before starting
            the measurement and then click on NEXT.
          </Text>
          <Box textAlign="left" w="100%" px="4" id="bpinstruct">
            <Flex alignItems="center" mt="2">
              <Icon
                as={AiFillCheckCircle}
                fill="red"
                w={{ vxs: 4, xxxs: 5 }}
                h={{ vxs: 4, xxxs: 5 }}
                mr="2"
              />
              <Text fontSize={{ vxs: 15, xs: 18 }}>
                Stay seated in a well-lit area and ensure that the main source of light, natural or artificial, is in front of your face.
              </Text>
            </Flex>
            <Text
              fontStyle="italic"
              fontSize={{ vxs: 10.5, xxxs: 12, xs: 13.5, sm: 14.5 }}
              ml="6"
              mb={{ vxs: "2", xxxs: "3", xs: "5", sm: "7" }}
            >
              (The five-star readout along the bottom of the next screen will
              tell you if you have enough light for an accurate measurement.
              Three or four stars is usually sufficient.)
            </Text>
            <Flex mt="4">
              <Icon
                as={AiFillCheckCircle}
                fill="red"
                w={{ vxs: 4, xxxs: 5 }}
                h={{ vxs: 4, xxxs: 5 }}
                mr="2"
              />
              <Text fontSize={{ vxs: 15, xs: 18 }}>
                Hold the phone in a stable position while the outline of your
                face is sketched.
              </Text>
            </Flex>
            <Flex mt="4">
              <Icon
                as={AiFillCheckCircle}
                fill="red"
                w={{ vxs: 4, xxxs: 5 }}
                h={{ vxs: 4, xxxs: 5 }}
                mr="2"
              />
              <Text fontSize={{ vxs: 15, xs: 18 }}>
                If you wear glasses, please remove them while the thirty-second
                measurement process is taking place.
              </Text>
            </Flex>
            <Flex mt="4">
              <Icon
                as={AiFillCheckCircle}
                fill="red"
                w={{ vxs: 4, xxxs: 5 }}
                h={{ vxs: 4, xxxs: 5 }}
                mr="2"
              />
              <Text fontSize={{ vxs: 15, xs: 18 }}>
                You might lose access in the next screen due to connectivity
                issues.
              </Text>
            </Flex>
          </Box>
        </VStack>
        <Button
          ref={bottomRef}
          mt={{
            vxs: "4 !important",
            xxxs: "auto !important",
            xxs: "4 !important",
            sm: "auto !important",
          }}
          mb="1 !important"
          w="100%"
          bg="red"
          color="white"
          py={{ vxs: "2", xxxs: "0", xxs: "2", sm: "2" }}
          fontSize={{ vxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={navigateToNeuralogix}
        >
          NEXT - Measure BP
        </Button>
      </PageMid>
      {/* <PageBottom btnTitle="Continue" onClick={handleContinue} /> */}
    </>
  );
};

export default BloodPressureInfo2;
