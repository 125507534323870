import { useState, useEffect, useRef } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";

import PageMid from "../components/PageMid";
import PageTop from "../components/PageTop";
import male from "../assets/male.png";
import female from "../assets/female.png";
import { useNavigate } from "react-router";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const CheckBMI = () => {
  const [feet, setFeet] = useState();
  const [inch, setInch] = useState();
  const [weight, setWeight] = useState();
  const [age, setAge] = useState();
  const [neck, setNeck] = useState();
  const [gender, setGender] = useState();

  const navigate = useNavigate();
  const currentGender = useRef();

  const bottomRef = useRef();
  const BmiSwal = withReactContent(Swal);

  useEffect(() => {
    currentGender.current = localStorage.getItem("ageGender");
    if (currentGender && currentGender.current) {
      setAge(JSON.parse(currentGender.current).age);
      setGender(JSON.parse(currentGender.current).gender);
    }
    window.scrollTo(0, 0);
  }, []);

  const handleCalculateBMI = () => {
    if (!feet || !weight){
      //return alert("Please enter height and weight");
      return BmiSwal.fire({
        title: 'Error!',
        text: 'Please enter height and weight',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red'
      });
    }

    let currentInch = "0";
    if (inch) {
      currentInch = inch;
    }

    const h = (Number(feet) * 12 + Number(currentInch)) * 2.54;
    const w = Number(weight) / 2.205;
    // h /= 39.3700787;
    // w /= 2.20462;
    // const BMI = ((w / Math.pow(h, 2)) * 100).toFixed(2);
    const BMI = ((w / (h * h)) * 10000).toFixed(2);
    console.log(BMI);
    localStorage.setItem("bmi", BMI);
    navigate("/result-bmi");
  };

  return (
    <>
      <PageTop heading="BMI Calculator" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <Text
          color="red"
          fontWeight="bold"
          fontSize={{ xs: 20, sm: 22 }}
          textAlign="center"
          w="100%"
          letterSpacing={0.5}
        >
          Enter your personal details, below
        </Text>
        <VStack
          color="white"
          w="100%"
          px="4"
          spacing={{ vxs: 2, xxxs: 4, xs: 10, sm: 14 }}
        >
          <FormControl isRequired>
            <FormLabel
              fontSize={{ vxs: 14, xs: 16, sm: 18 }}
              mb="2 !important"
              className="formlabel"
            >
              Gender
            </FormLabel>
            <HStack justifyContent="space-around" mt={{ xs: 4 }}>
              <Flex alignItems="center" onClick={() => setGender("M")}>
                <Image
                  src={male}
                  boxSize={{ vxs: "25px", xxxs: "35px" }}
                  bg={gender === "M" || gender === "Male" ? "red" : "white"}
                  borderRadius="50%"
                  mr="2"
                />
                <Text
                  fontSize={{ vxs: 14, xs: 16, sm: 18 }}
                  color={gender === "M" || gender === "Male" ? "red" : "white"}
                >
                  Male
                </Text>
              </Flex>
              <Flex alignItems="center" onClick={() => setGender("F")}>
                <Image
                  src={female}
                  boxSize={{ vxs: "25px", xxxs: "35px" }}
                  bg={gender === "F" || gender === "Female" ? "red" : "white"}
                  borderRadius="50%"
                  mr="2"
                />
                <Text
                  fontSize={{ vxs: 14, xs: 16, sm: 18 }}
                  color={
                    gender === "F" || gender === "Female" ? "red" : "white"
                  }
                >
                  Female
                </Text>
              </Flex>
            </HStack>
          </FormControl>
          <FormControl isRequired>
            <FormLabel
              fontSize={{ vxs: 14, xs: 16, sm: 18 }}
              mb="2 !important"
              className="formlabel"
            >
              Height
            </FormLabel>
            <HStack justifyContent="space-between">
              <Flex alignItems="center">
                <Select
                  mr="2"
                  onChange={(e) => setFeet(e.target.value)}
                  value={feet}
                >
                  <option style={{ color: "black" }} value={1}>
                    1
                  </option>
                  <option style={{ color: "black" }} value={2}>
                    2
                  </option>
                  <option style={{ color: "black" }} value={3}>
                    3
                  </option>
                  <option style={{ color: "black" }} value={4}>
                    4
                  </option>
                  <option style={{ color: "black" }} value={5}>
                    5
                  </option>
                  <option style={{ color: "black" }} value={6}>
                    6
                  </option>
                  <option style={{ color: "black" }} value={7}>
                    7
                  </option>
                </Select>
                <Text fontSize={{ vxs: 14, xs: 16, sm: 18 }} color="red">
                  Feet
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Select
                  mr="2"
                  onChange={(e) => setInch(e.target.value)}
                  value={inch}
                >
                  <option style={{ color: "black" }} value={0}>
                    0
                  </option>
                  <option style={{ color: "black" }} value={1}>
                    1
                  </option>
                  <option style={{ color: "black" }} value={2}>
                    2
                  </option>
                  <option style={{ color: "black" }} value={3}>
                    3
                  </option>
                  <option style={{ color: "black" }} value={4}>
                    4
                  </option>
                  <option style={{ color: "black" }} value={5}>
                    5
                  </option>
                  <option style={{ color: "black" }} value={6}>
                    6
                  </option>
                  <option style={{ color: "black" }} value={7}>
                    7
                  </option>
                  <option style={{ color: "black" }} value={8}>
                    8
                  </option>
                  <option style={{ color: "black" }} value={9}>
                    9
                  </option>
                  <option style={{ color: "black" }} value={10}>
                    10
                  </option>
                  <option style={{ color: "black" }} value={11}>
                    11
                  </option>
                </Select>
                <Text fontSize={{ vxs: 14, xs: 16, sm: 18 }} color="red">
                  Inch
                </Text>
              </Flex>
            </HStack>
          </FormControl>
          <FormControl isRequired>
            <FormLabel
              fontSize={{ vxs: 14, xs: 16, sm: 18 }}
              mb="2 !important"
              className="formlabel"
            >
              Weight
            </FormLabel>
            <Flex color="white" alignItems="flex-end">
              <Input
                type="number"
                h="max-content"
                w="30%"
                mr="4"
                border="1px solid white"
                p="2"
                placeholder=""
                _focusVisible={{ border: "1px solid white" }}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
              <Text fontSize={{ vxs: 14, xs: 16, sm: 18 }} color="red">
                Pounds
              </Text>
            </Flex>
          </FormControl>
          <FormControl isRequired>
            <FormLabel
              fontSize={{ vxs: 14, xs: 16, sm: 18 }}
              mb="2 !important"
              className="formlabel"
            >
              Age
            </FormLabel>
            <Flex color="white" alignItems="flex-end">
              <Input
                type="number"
                h="max-content"
                w="30%"
                mr="4"
                border="1px solid white"
                p="2"
                _focusVisible={{ border: "1px solid white" }}
                value={age}
                onChange={(e) => setAge(e.target.value)}
              />
              <Text fontSize={{ vxs: 14, xs: 16, sm: 18 }} color="red">
                Years
              </Text>
            </Flex>
          </FormControl>
          <FormControl>
            <FormLabel fontSize={{ vxs: 14, xs: 16, sm: 18 }}>
              Neck (optional)
            </FormLabel>
            <Flex color="white" alignItems="flex-end">
              <Input
                type="number"
                h="max-content"
                w="30%"
                mr="4"
                border="1px solid white"
                p="2"
                _focusVisible={{ border: "1px solid white" }}
                onChange={(e) => setNeck(e.target.value)}
              />
              <Text fontSize={{ vxs: 14, xs: 16, sm: 18 }} color="red">
                Inch
              </Text>
            </Flex>
          </FormControl>
        </VStack>
        <Button
          ref={bottomRef}
          mt="auto !important"
          mb="3 !important"
          w="100%"
          bg="red"
          color="white"
          fontSize={{ vxs: 14, xxxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={handleCalculateBMI}
        >
          NEXT - Calculate BMI
        </Button>
      </PageMid>
    </>
  );
};

export default CheckBMI;
