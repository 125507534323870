import { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";

import PageMid from "../components/PageMid";
import PageTop from "../components/PageTop";
import bgInvert from "../assets/bi_invert.png";
import { useNavigate } from "react-router";

const BPResult = () => {
  const [result, setResult] = useState({
    systolic: 126,
    diastolic: 79,
    heartRateVariability: 60,
    breathing: 40,
  });

  const navigate = useNavigate();

  const bottomRef = useRef();

  useEffect(() => {
    const scanComplete = async (id) => {
      try {
        const url = `https://healthscanner.app/service/scanComplete?userName=${id}&scanDateTime=${new Date().toISOString()}`;

        const res = await axios.post(url);

        //Only set when scan is complete
        if (localStorage.getItem("login")) {
          const loginDetails = JSON.parse(localStorage.getItem("login"));
          if (loginDetails.username) {
            localStorage.setItem("lastScan", moment().format("DD-MM-YYYY"));
          }
        }
        console.log("Scan complete:: ", res);
      } catch (err) {
        console.log(err);
      }
    };

    (async () => {
      if (localStorage.getItem("login")) {
        const loginDetails = JSON.parse(localStorage.getItem("login"));

        if (loginDetails.username) {
          //localStorage.setItem("lastScan", moment().format("DD-MM-YYYY"));
          await scanComplete(
            `${loginDetails.countryCode}${loginDetails.username}`
          );
        }
      }

      if (localStorage.getItem("results")) {
        const results = JSON.parse(localStorage.getItem("results"));
        setResult({
          systolic: results.BP_SYSTOLIC.toFixed(2),
          diastolic: results.BP_DIASTOLIC.toFixed(2),
          heartRateVariability: results.HR_BPM.toFixed(2),
          breathing: results.BR_BPM.toFixed(2),
        });
      }
    })();

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTop heading="Blood Pressure Results" />
      <PageMid
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={1}
          mb={{ vxs: "3 !important", xxxs: "6 !important" }}
        >
          <GridItem
            bg="linear-gradient(0deg,
                rgba(136, 136, 136, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(143, 143, 143, 1) 100%)"
            w="100%"
            p={{ vxs: "2", xs: "3" }}
          >
            <Text
              fontWeight="bold"
              fontSize={{ vxs: 16, sm: 18 }}
              textAlign="center"
              mb={{ vxs: "1", xs: "2" }}
            >
              Systolic
            </Text>
            <Box
              color="red"
              fontSize={{ vxs: 14, sm: 17 }}
              textAlign="center"
              fontWeight="bold"
            >
              <Text>{result.systolic}</Text>
              <Text>mmHG</Text>
            </Box>
          </GridItem>
          <GridItem
            bg="linear-gradient(0deg,
                rgba(136, 136, 136, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(143, 143, 143, 1) 100%)"
            w="100%"
            p={{ vxs: "1", xs: "2" }}
          >
            <Text
              fontWeight="bold"
              fontSize={{ vxs: 16, sm: 18 }}
              textAlign="center"
              mb="3"
            >
              Diastolic
            </Text>
            <Box
              color="red"
              fontSize={{ vxs: 14, sm: 17 }}
              textAlign="center"
              fontWeight="bold"
            >
              <Text>{result.diastolic}</Text>
              <Text>mmHG</Text>
            </Box>
          </GridItem>
          <GridItem
            bg="linear-gradient(0deg,
                rgba(136, 136, 136, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(143, 143, 143, 1) 100%)"
            w="100%"
            p={{ vxs: "1", xs: "2" }}
          >
            <Text
              fontWeight="bold"
              fontSize={{ vxs: 16, sm: 18 }}
              textAlign="center"
              mb="3"
            >
              Heart Rate
            </Text>
            <Box
              color="red"
              fontSize={{ vxs: 14, sm: 17 }}
              textAlign="center"
              fontWeight="bold"
            >
              <Text>{result.heartRateVariability}</Text>
              <Text>BPM</Text>
            </Box>
          </GridItem>
          <GridItem
            bg="linear-gradient(0deg,
                rgba(136, 136, 136, 1) 0%,
                rgba(255, 255, 255, 1) 50%,
                rgba(143, 143, 143, 1) 100%)"
            w="100%"
            p={{ vxs: "1", xs: "2" }}
          >
            <Text
              fontWeight="bold"
              fontSize={{ vxs: 14, sm: 18 }}
              textAlign="center"
              mb="3"
            >
              Respiration Rate
            </Text>
            <Box
              color="red"
              fontSize={{ vxs: 14, sm: 17 }}
              textAlign="center"
              fontWeight="bold"
            >
              <Text>{result.breathing}</Text>
              <Text>BPM</Text>
            </Box>
          </GridItem>
        </Grid>
        <VStack
          color="white"
          p={{ vxs: "1", xxxs: "2" }}
          fontSize={{ vxs: 12.5, xxxs: 14, xs: 16, sm: 17 }}
          spacing={{ vxs: 6, xs: 8, sm: 10 }}
          mt={{ vxs: "-2 !important", xxxs: 0 }}
        >
          <Text textAlign="justify" mt="4 !important">
            You will be able to retake this measurement every five days in order
            to monitor your ongoing health situation. For reasons of personal
            privacy, this app does not retain your data so you will need to do a
            screen-shot of these results if you want to maintain a permanent
            record.
          </Text>
          <Box
            bgImage={bgInvert}
            bgPos="center"
            bgRepeat="no-repeat"
            bgSize="cover"
            bgBlendMode="lighten"
            px="3"
            pb="3"
            rounded="md"
            mt={{
              vxs: "2 !important",
              xxs: "6 !important",
              xs: "8 !important",
            }}
          >
            <Text
              textAlign="justify"
              mt={{ vxs: "2 !important", xxxs: "4 !important" }}
              color="black"
            >
              NOTICE: These results are estimates only and are not a substitute
              for results derived from a medical device. If systolic pressure is
              regularly over 160mmHG, validation by a medical device may be
              needed.
            </Text>
          </Box>
        </VStack>
        <Button
          ref={bottomRef}
          mt="auto !important"
          mb="3 !important"
          w="100%"
          bg="red"
          color="white"
          fontSize={{ vxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={() => navigate("/pdriver-info")}
        >
          NEXT - Health Consequences
        </Button>
      </PageMid>
    </>
  );
};

export default BPResult;
