import { useState, createRef } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdHeight } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { AiOutlineFontSize } from "react-icons/ai";
import { useFormik } from "formik";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

import bgImage from "../assets/bgImage.png";
import boxImg from "../assets/boxImg.png";
import logo from "../assets/logo.png";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const download = () => {
    document.getElementById("divToPrint").style.display = "grid";
    const width = document.getElementById("divToPrint").clientWidth;
    const height = document.getElementById("divToPrint").clientHeight;
    console.log(height, width);
    const topLeftMargin = 15;
    const pdfWidth = width + topLeftMargin * 2;
    const pdfHeight = pdfWidth * 1.5 + topLeftMargin * 2;
    const canvasImgWidth = width;
    const canvasImgHeight = height;

    const totalPdfPages = Math.ceil(height / pdfHeight) - 1;

    const el = document.getElementById("divToPrint");

    html2canvas(el, { allowTaint: true, scale: 0.7 }).then((canvas) => {
      canvas.getContext("2d");

      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
      pdf.addImage(
        imgData,
        "JPG",
        topLeftMargin,
        topLeftMargin,
        canvasImgWidth,
        canvasImgHeight
      );

      for (let i = 1; i <= totalPdfPages; i++) {
        pdf.addImage(pdfWidth, pdfHeight);
        pdf.addImage(
          imgData,
          "JPG",
          topLeftMargin,
          -(pdfHeight * i) + topLeftMargin * 4,
          canvasImgWidth,
          canvasImgHeight
        );
      }

      pdf.save("download.pdf");
    });

    el.style.display = "none";
  };

  const formFormik = useFormik({
    initialValues: {
      width: "",
      height: "",
      users: "",
      fSize: "",
    },
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const url = `https://healthscanner.app/service/generateUser?hostName=healthscanner.app&width=${values.width}&height=${values.height}&fontSize=${values.fSize}&noOfUsers=${values.users}`;

        // const res = await fetch(url);
        // const data = await res.json();

        const data = await axios(url);

        setResult(data.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    },
  });

  const { values, handleChange, handleSubmit } = formFormik;

  return (
    <Box
      minH="100vh"
      backgroundImage={`linear-gradient(to right, rgba(222, 40, 72, 1), rgba(222, 48, 76, 0.8)), url('${bgImage}')`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      p={10}
      overflow="hidden"
    >
      <Image src={logo} mb={10} />
      <HStack alignItems="flex-start">
        <HStack bg="white" w="50%" justifyContent="space-between" rounded="lg">
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <VStack p="5" alignItems="flex-start" w="60%" spacing={5} mx="auto">
              <HStack>
                <Box>
                  <FormControl isRequired>
                    <FormLabel htmlFor="width" color="#A3A3A3">
                      Width
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="#717171"
                        children={<MdHeight />}
                        transform="rotate(90deg)"
                        fontSize={20}
                      />
                      <Input
                        type="number"
                        id="width"
                        name="width"
                        value={values.width}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel htmlFor="height" color="#A3A3A3">
                      Height
                    </FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        color="#717171"
                        children={<MdHeight />}
                        fontSize={20}
                      />
                      <Input
                        type="number"
                        id="height"
                        name="height"
                        value={values.height}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormControl>
                </Box>
              </HStack>
              <Box>
                <FormControl isRequired>
                  <FormLabel htmlFor="users" color="#A3A3A3">
                    Number of users
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="#717171"
                      children={<HiUsers />}
                      fontSize={20}
                    />
                    <Input
                      type="number"
                      id="users"
                      name="users"
                      value={values.users}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControl isRequired>
                  <FormLabel htmlFor="fontSize" color="#A3A3A3">
                    Font Size
                  </FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="#717171"
                      children={<AiOutlineFontSize />}
                      fontSize={20}
                    />
                    <Input
                      type="number"
                      id="fontSize"
                      name="fSize"
                      value={values.fSize}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Flex w="100%" justifyContent="space-between">
                <Button
                  bg="#DF2949"
                  color="white"
                  w={!result && "100%"}
                  alignSelf="stretch"
                  // mx="auto"
                  _hover={{ bg: "#DF2949" }}
                  _focus={{ bg: "#DF2949" }}
                  type="submit"
                  isLoading={isLoading}
                >
                  Submit
                </Button>
                {result && (
                  <Button colorScheme="blue" onClick={download}>
                    Download
                  </Button>
                )}
              </Flex>
            </VStack>
          </form>
          <Image src={boxImg} />
        </HStack>
        <Box>
          {result && (
            <Grid
              bg="white"
              templateColumns="repeat(3, 1fr)"
              gap={3}
              ml={4}
              // h="500px"
              rounded="md"
              // overflowY="scroll"
              // visibility="hidden"
              display="none"
              id="divToPrint"
            >
              {result.generatedUsers.map((item) => (
                <GridItem>
                  <Image
                    src={`data:image/png;base64,${item.qrCodeEncodedToBase64}`}
                  />
                </GridItem>
              ))}
            </Grid>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

export default Dashboard;
