const CACHE_NAME = "v1";
const TOKEN_KEY = "login";
const OTP_KEY = "otpVerified";
const FAKE_TOKEN = "sRKWQu6hCJgR25lslcf5s12FFVau0ugi";

const FAKE_ENDPOINT = "/offlinetoken";

export const saveLogin = async (login: string) => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const responseBody = JSON.stringify({
      [TOKEN_KEY]: login
    });
    const response = new Response(responseBody);
    await cache.put(FAKE_ENDPOINT, response);
    console.log("Token saved!");
  } catch (error) {
    // It's up to you how you resolve the error
    console.log("saveToken error:", { error });
  }
};

export const getLogin = async () => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(FAKE_ENDPOINT);

    if (!response) {
      return null;
    }

    const responseBody = await response.json();
    return JSON.stringify(responseBody);//responseBody[TOKEN_KEY];
  } catch (error) {
    // Gotta catch 'em all
    console.log("getToken error:", { error });
  }
};

/*
export const saveOtp = async (otp: string) => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const responseBody = JSON.stringify({
      [OTP_KEY]: otp
    });
    const response = new Response(responseBody);
    await cache.put(FAKE_ENDPOINT, response);
    console.log("Token saved! 🎉");
  } catch (error) {
    // It's up to you how you resolve the error
    console.log("saveToken error:", { error });
  }
};

export const getOtp = async () => {
  try {
    const cache = await caches.open(CACHE_NAME);
    const response = await cache.match(FAKE_ENDPOINT);

    if (!response) {
      return null;
    }

    const responseBody = await response.json();
    return responseBody[OTP_KEY];
  } catch (error) {
    // Gotta catch 'em all
    console.log("getToken error:", { error });
  }
}; */

export const removeLogin = async () => {
  try {
    const cache = await caches.open(CACHE_NAME);
    caches.keys().then((keyList) => {
      keyList.map((key) => {
        if (key === CACHE_NAME) {
            return caches.delete(key);
        }
      })
    });
    console.log("Token saved! 🎉");
  } catch (error) {
    // It's up to you how you resolve the error
    console.log("saveToken error:", { error });
  }
};
