import { Button, Image, Link, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useEffect, useRef } from "react";

import PageMid from "../components/PageMid";
import PageTop from "../components/PageTop";
import higi from "../assets/higi-logo.png";

const PDriverInfo = () => {
  const navigate = useNavigate();

  const bottomRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageTop heading="Health Consequences" />
      <PageMid
        p="2"
        handleDown={() => {
          setImmediate(() =>
            bottomRef.current.scrollIntoView({ behavior: "smooth" })
          );
        }}
      >
        <VStack
          color="white"
          fontSize={{ vxs: 13, xxxs: 15, xs: 16, sm: 19 }}
          spacing={{ vxs: 3, xxxs: 3, xxs: 2, xs: 3, sm: 4 }}
          textAlign="justify"
        >
          <Text mt={{ vxs: "3 !important", xs: "5 !important" }}>
            This blood pressure scan is not intended to replace the standard,
            cuff-systems that are traditionally used for this measurement. If
            your systolic reading is 130, or above, and/or your diastolic
            reading is 90, or above, on multiple occasions, please test again
            using a standard cuff. If high BP is confirmed, please consult your
            physician as soon as you can.
          </Text>
          <Image src={higi} w={{ vxs: "150px", xxxs: "250px" }} />
          <Text mt={{ vxs: "3 !important", xs: "5 !important" }}>
            To easily verify your blood pressure readings there are 6,000 health
            check stations that can be accessed, free-of-charge, at multiple
            retail sites across the country. For your nearest location go to{" "}
            <Link
              href="https://secure.higi.com/locator/mobile"
              color="blue.400"
              borderBottom="1px solid"
              isExternal
            >
              https://secure.higi.com/locator/mobile
            </Link>{" "}
          </Text>
          <Text mt="3 !important">
            A driver with a BP reading above 140/90 at their DOT physical will
            not get the full, two-year certification. A BP reading of 180/110
            means disqualification.
          </Text>
        </VStack>
        <Button
          ref={bottomRef}
          mt={{
            vxs: "4 !important",
            xxxs: "auto !important",
            xxs: "3 !important",
            xs: "auto !important",
          }}
          mb="3 !important"
          w="100%"
          bg="red"
          color="white"
          py={{ vxs: "2", xxxs: "0", xxs: "2" }}
          fontSize={{ vxs: 16, xs: 20 }}
          _hover={{ bg: "red" }}
          _focus={{ bg: "red" }}
          _active={{ bg: "red" }}
          onClick={() => navigate("/check-bmi")}
        >
          NEXT - Body Mass Index
        </Button>
      </PageMid>
    </>
  );
};

export default PDriverInfo;
