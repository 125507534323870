import { Navigate } from "react-router";

const PrivateRoute = ({ children }) => {
  return localStorage.getItem("login") &&
    localStorage.getItem("otpVerified") ? (
    children
  ) : !localStorage.getItem("otpVerified") ? (
    <Navigate to="/otp-verify" />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
