import { Flex, Icon, VStack } from "@chakra-ui/react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineQuestion } from "react-icons/ai";
import { useToast } from "@chakra-ui/react";

import biBg from "../assets/bi.png";

const PageMid = ({ children, extraStyles, handleDown }) => {
  const toast = useToast();

  const handleHelp = () => {
    toast({
      title: "Help",
      description: `In the event of any difficulties, please send an email describing the problem to support@cdlhealthscanner.com and we will respond within 24 hours. Thank you.`,
      status: "error",
      duration: 5000,
    })
  }

  return (
    <VStack
      id="page-mid"
      pt={{ vxs: "2", xxxs: "2" }}
      pb={{ vxs: "1", xxxs: "2" }}
      pr={{ vxs: "3", xxxs: "4" }}
      pl={{ vxs: "3", xxxs: "4" }}
      height="90vh"
      bgImage={biBg}
      bgPos="center"
      bgRepeat="no-repeat"
      bgSize="cover"
      overflowY="scroll"
      overflowX="hidden"
      position="relative"
      {...extraStyles}
    >
      <>
        {children}
        <Flex
          position="fixed"
          bottom={57}
          right={5}
          bg="red.400"
          p="1"
          borderRadius="full"
          opacity=".8"
          cursor="pointer"
          onClick={handleHelp}
        >
          <Icon as={AiOutlineQuestion} w={7} h={7} />
        </Flex>

        <Flex
          position="fixed"
          bottom={5}
          right={5}
          bg="red.400"
          p="1"
          borderRadius="full"
          opacity=".8"
          cursor="pointer"
          onClick={handleDown}
        >

          <Icon as={AiOutlineArrowDown} w={7} h={7} />
        </Flex>
      </>
    </VStack>
  );
};

export default PageMid;
