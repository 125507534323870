export const data = [
  {
    name: "Underweight",
    uv: 17,
  },
  {
    name: "Healthy",
    uv: 20,
  },
  {
    name: "Overweight",
    uv: 27,
  },
  {
    name: "Obese",
    uv: 35,
  },
];
